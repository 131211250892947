const authorization = 'QUxzcFVNZGlnOmEwZTdkMDY0LTI0ZjktNDYzNy05NTZhLTEyZDA3OTQ2N2JkOA=='

module.exports.reactivesearch = async (index, query) => {
  const body = {
    settings:{
      recordAnalytics: true,
      enableQueryRules: true
    },
    query
  }

  const result = await fetch(
    `https://bookis-prod-nnwvygu-arc.searchbase.io/${index}/_reactivesearch.v3`,
    {
      method: 'POST',
      headers: { 'authorization': `Basic ${authorization}` },
      body: JSON.stringify(body)
    }
  ).then(res => res.json())

  return result
}