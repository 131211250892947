'use client'

import { QuickSearchResultInterface } from '@hermes/types/src/@types/api-legacy/search'
import {
  ArrowBackIcon,
  Button,
  CloseCircleIcon,
  SearchIcon,
  useClickOutside,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { quickSearch } from '@lib/services/search'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import RecentSearch from './Recent'
import SearchResult from './Result'
import { SearchAreaContainer, StyledSearchArea } from './Search.styles'

const SearchArea = () => {
  const theme = useTheme()
  const router = useRouter()
  const locale = useLocale()
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState<QuickSearchResultInterface | undefined>(undefined)
  const [recentSearchView, setRecentSearchView] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const resultsContainerRef = useRef<HTMLDivElement>(null)
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT
  // eslint-disable-next-line no-nested-ternary
  const stage = env === 'development' ? 'dev-' : env === 'staging' ? 'staging-' : ''
  const region = getRegionFromLocale(locale)
  const { t } = useTranslateMessage()

  const hideSearch = () => {
    setRecentSearchView(false)
    setSearchResult(undefined)
  }

  useClickOutside(resultsContainerRef, () => hideSearch())

  useEffect(() => {
    if ((recentSearchView || searchResult) && !isDesktop) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [recentSearchView, searchResult])

  const searchInputChange = (value: string) => {
    setSearchValue(value)
    if (value.length > 3) {
      quickSearch(value).then((resp) => {
        setRecentSearchView(false)
        setSearchResult(resp as QuickSearchResultInterface)
      })
    } else setRecentSearchView(true)
  }

  const pushToSearch = () => {
    hideSearch()
    const recentSearch = JSON.parse(localStorage.getItem(`recentSearch-${stage}${region}`) || '[]') as string[]
    if (recentSearch.length <= 2) {
      recentSearch.unshift(searchValue)
    } else {
      recentSearch.pop()
      recentSearch.unshift(searchValue)
    }
    localStorage.setItem(`recentSearch-${stage}${region}`, JSON.stringify(recentSearch))

    router.push(`/${locale}/search?search=${searchValue}&searchView=Grid`)
  }

  return (
    <SearchAreaContainer ref={resultsContainerRef}>
      <StyledSearchArea
        hiddenLabel
        variant="standard"
        placeholder={t({ id: 'header.search.placeholder' })}
        value={searchValue}
        onChange={(e) => searchInputChange(e.target.value)}
        onKeyDown={(e) => e.code === 'Enter' && pushToSearch()}
        InputProps={{
          onClick: () => !recentSearchView && setRecentSearchView(true),
          startAdornment:
            !isDesktop && recentSearchView ? (
              <Button onClick={hideSearch} aria-label="back" variant="ghost">
                <ArrowBackIcon width="24px" />
              </Button>
            ) : (
              <Button onClick={pushToSearch} aria-label="search" className="search-action-button" variant="ghost">
                <SearchIcon width="24px" height="26px" />
              </Button>
            ),
          endAdornment: (
            <>
              {Boolean(searchValue.length) && (
                <Button onClick={() => setSearchValue('')} className="clear-search-value" variant="ghost">
                  <CloseCircleIcon width="24px" />
                </Button>
              )}
            </>
          )
        }}
      />
      {searchResult && !recentSearchView && (
        <SearchResult hideSearch={hideSearch} searchValue={searchValue} {...searchResult} />
      )}
      {recentSearchView && <RecentSearch hideSearch={hideSearch} />}
    </SearchAreaContainer>
  )
}

export default SearchArea
