import { Box, Button, Paper, styledComponent } from '@hermes/web-components'

export const RecentSearchContainer = styledComponent(Paper)(({ theme }) => ({
  top: 'calc(100% + 6px)',
  position: 'absolute',
  boxShadow: '0 4px 12px rgb(0 0 0 / 8%)',
  padding: '0 16px 16px',
  zIndex: 999,
  borderRadius: '8px',
  width: '570px',
  '.book-card': {
    '.book-card-title': {
      WebkitLineClamp: '1'
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    position: 'fixed',
    top: '74px',
    left: 0,
    height: 'calc(100vh - 74px)'
  }
}))

export const RecentTitle = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  margin: '16px 0 4px 0',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    margin: '19px 0 9px 0'
  },
  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.neutral.main,
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'PT Serif,"serif"',
      color: theme.palette.neutral[900],
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 600
    }
  },
  '.MuiButtonBase-root': {
    fontSize: '14px',
    lineHeight: '20px',
    padding: 0,
    height: 'auto',
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 12px',
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
}))

export const BrowseCategoriesButton = styledComponent(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '16px 0',
  color: theme.palette.neutral[900],
  height: 'auto',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  wordSpacing: '1px',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  marginBottom: '27px',
  fontSize: '14px',
  lineHeight: '20px',
  '.MuiBox-root': {
    gap: '8px',
    display: 'inline-flex',
    alignItems: 'center'
  }
}))
