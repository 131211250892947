import BookImage from '@components/BookImage'
import { SuggestionButtonSearch } from '@components/Result'
import { BookCardContainer } from '@components/Result.styles'
import { BookInterface } from '@hermes/types/src/@types/api-legacy/book'
import { ElasticBook } from '@hermes/types/src/@types/api-legacy/search'
import { BookCard, BookIcon, Box, Button, Chevron, Typography, useMediaQuery, useTheme } from '@hermes/web-components'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

import { useLocale } from '@hooks/useLocale'
import { useNavigation } from '@hooks/useNavigation'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { BrowseCategoriesButton, RecentSearchContainer, RecentTitle } from './Recent.styles'

interface RecentSearchProps {
  hideSearch: () => void
}

const RecentSearch = ({ hideSearch }: RecentSearchProps) => {
  const router = useRouter()
  const theme = useTheme()
  const locale = useLocale()
  const navigation = useNavigation()
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT
  // eslint-disable-next-line no-nested-ternary
  const stage = env === 'development' ? 'dev-' : env === 'staging' ? 'staging-' : ''
  const [recentSearch, setRecentSearch] = useState(
    JSON.parse(localStorage.getItem(`recentSearch-${stage}${locale}`) || '[]') || []
  )
  const [recentViewed, setRecentViewed] = useState(
    JSON.parse(localStorage.getItem(`recentViewed-${stage}${locale}`) || '[]') || []
  )
  const { t } = useTranslateMessage()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const clearRecent = (key: 'recentSearch' | 'recentViewed') => {
    localStorage.removeItem(`${key}-${stage}${locale}`)
    if (key === 'recentSearch') setRecentSearch(undefined)
    if (key === 'recentViewed') setRecentViewed(undefined)
  }

  const pushToBDP = (book: BookInterface) => {
    if (window.location.pathname !== `/books/${book.slug}`) hideSearch()
    const recentViewedToSave = JSON.parse(
      localStorage.getItem(`recentViewed-${stage}${locale}`) || '[]'
    ) as BookInterface[]
    if (recentViewedToSave.length <= 2) {
      recentViewedToSave.unshift(book)
    } else {
      recentViewedToSave.pop()
      recentViewedToSave.unshift(book)
    }
    localStorage.setItem(`recentViewed-${stage}${locale}`, JSON.stringify(recentViewedToSave))
    navigation.navigate.productPage(book.slug)
  }

  const pushToSearch = (value: string) => {
    hideSearch()
    router.push(`/${locale}/search?search=${value}&searchView=Grid`)
    navigation.navigate.searchPage(value)
  }

  return (
    <>
      {((recentSearch && Boolean(recentSearch.length)) || (recentViewed && Boolean(recentViewed.length))) && (
        <RecentSearchContainer>
          {!isDesktop && (
            <BrowseCategoriesButton onClick={() => router.push(`/${locale}/categories`)}>
              <Box>
                <BookIcon width="20px" />
                {t({ id: 'header.search.recent.browseCategories' })}
              </Box>
              <Chevron width="20px" style={{ rotate: '-90deg' }} />
            </BrowseCategoriesButton>
          )}
          {recentSearch && Boolean(recentSearch.length) && (
            <>
              <RecentTitle>
                <Typography variant="body2">{t({ id: 'header.search.recentSearch' })}</Typography>
                <Button onClick={() => clearRecent('recentSearch')} variant={isDesktop ? 'text' : 'outlined'}>
                  {t({ id: 'clear' })}
                </Button>
              </RecentTitle>
              {recentSearch.map((item: string, index: number) => (
                <Box display="flex" flexDirection="column" key={index}>
                  <SuggestionButtonSearch onClick={() => pushToSearch(item)} label={item} />
                </Box>
              ))}
            </>
          )}
          {recentViewed && Boolean(recentViewed.length) && (
            <>
              <RecentTitle>
                <Typography variant="body2">{t({ id: 'header.search.recentViewed' })}</Typography>
                <Button onClick={() => clearRecent('recentViewed')} variant={isDesktop ? 'text' : 'outlined'}>
                  {t({ id: 'clear' })}
                </Button>
              </RecentTitle>
              {recentViewed.map((item: ElasticBook | BookInterface, index: number) => (
                <BookCardContainer key={index}>
                  <BookCard
                    title={`${item.title}${item.subtitle ? `: ${item.subtitle}` : ''}`}
                    cardAction={() => pushToBDP(item as BookInterface)}
                    image={
                      <BookImage
                        src={(item as ElasticBook).image ?? (item as BookInterface).fullImage}
                        width={40}
                        height={56}
                        style={{ objectFit: 'contain' }}
                        alt={item.title}
                      />
                    }
                    cover={t({ id: item.format! })}
                    year={String(item.year)}
                    inline
                  />
                </BookCardContainer>
              ))}
            </>
          )}
        </RecentSearchContainer>
      )}
    </>
  )
}

export default RecentSearch
