import React, { memo } from 'react'
import BaseImage, { BaseImageProps } from './BaseImage'

const DEFAULT_IMAGE = '/img/default-avatar.png'

const Avatar = memo((props: Omit<BaseImageProps, 'defaultImageSrc'>) => (
  <BaseImage {...props} style={{ borderRadius: '100%', objectFit: 'cover' }} defaultImageSrc={DEFAULT_IMAGE} />
))

export default Avatar
