const priceInlineSort = `
  if (doc['priceNewFrom'].size() == 0 || doc['priceUsedFrom'].size() == 0) {
    return 0;
  }

  if(doc['availableNew'].value != null && doc['availableNew'].value != false) {
    if(
      doc['availableUsed'].value != null &&
      doc['availableUsed'].value != 0 &&
      doc['priceNewFrom'].value > doc['priceUsedFrom'].value
    ) {
      return doc['priceUsedFrom'].value;
    } else {
      return doc['priceNewFrom'].value;
    }
  } else {
    return doc['priceUsedFrom'].value;
  }        
`

const salePriceInlineSort = sellerId => `
  def price=0;
  for(s in params._source.sales) {
    if(s.sellerId==${sellerId}) {
      if(price==0 || price>s.price) {
        price=s.price;
      }
    }
  }
  return price;
`

const sortQueries = {
  new: [{ year: 'desc' }, { createdAt: 'desc' }],
  newAsc: [{ year: 'asc' }, { createdAt: 'desc' }],
  popularSale: [{ availableUsed: 'desc' }, { year: 'desc' }],
  popular: [{ popularity: 'desc' }, { year: 'desc' }],
  price: {
    _script: {
      type: 'number',
      script: {
        lang: 'painless',
        inline: priceInlineSort,
      },
      order: 'asc',
    },
  },
  priceDec: {
    _script: {
      type: 'number',
      script: {
        lang: 'painless',
        inline: priceInlineSort,
      },
      order: 'desc',
    },
  },
  salePrice: sellerId => ({
    _script: {
      type: 'number',
      script: {
        lang: 'painless',
        inline: salePriceInlineSort(sellerId),
      },
      order: 'asc',
    },
  }),
  salePriceDec: sellerId => ({
    _script: {
      type: 'number',
      script: {
        lang: 'painless',
        inline: salePriceInlineSort(sellerId),
      },
      order: 'desc',
    },
  }),
  az: { 'title.keyword': 'asc' },
  rating: { avgRating: 'desc' },
}

const getBooksSearchQuery = ({
  useFilters = true,
  search = '',
  page = 0,
  perPage = 10,
  sortType,
  author,
  availableNew,
  availableUsed,
  formats,
  yearMin,
  yearMax,
  priceMax,
  priceMin,
  ratingMin,
  ratingMax,
  genres,
  languages,
  sellerId
}) => {
  const booksQuery = {
    id: 'books',
    type: 'search',
    dataField: ['_score'],
    execute: true,
    react: { and: ['search'] },
    highlight: false,
    size: perPage,
    from: page * perPage,
  }

  if (['salePrice', 'salePriceDec'].includes(sortType)) {
    if (sellerId) {
      booksQuery.defaultQuery = { sort: sortQueries[sortType](sellerId) }
    }
  } else if (sortType) {
    booksQuery.defaultQuery = { sort: sortQueries[sortType] }
  }

  const searchQuery = {
    id: 'search',
    type: 'search',
    execute: false,
    value: search.trim()
  }

  const availableOnlyQueries = [
    {
      id: 'availableOnly',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: [{
              bool: {
                should: [
                  { term: { availableNew: true } },
                  { range: { availableUsed: { gt: 0 } } },
                ],
                minimum_should_match: 1,
              },
            }]
          },
        }
      }
    },
    {
      id: 'availableNewOnly',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: [{
              bool: {
                should: [
                  { term: { availableNew: true } },
                ],
                minimum_should_match: 1,
              },
            }]
          },
        }
      }
    },
    {
      id: 'availableUsedOnly',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: [{
              bool: {
                should: [
                  { range: { availableUsed: { gt: 0 } } },
                ],
                minimum_should_match: 1,
              },
            }]
          },
        }
      }
    },
  ]

  const filterValueQueries = useFilters ? [
    ...[
      { id: 'languages' },
      { id: 'year', type: 'range' },
      { id: 'format' },
      { id: 'availableUsed' },
      { id: 'availableNew' },
      { id: 'priceNewFrom', type: 'range', react: ['availableNewOnly'] },
      { id: 'priceUsedFrom', type: 'range', react: ['availableUsedOnly'] },
      { id: 'avgRating', type: 'range' },
      { id: 'genres', aggregationSize: 10000 },
    ].map(f => ({
      id: f.id,
      type: f.type || 'term',
      dataField: [f.id],
      aggregationSize: f.aggregationSize || 50,
      execute: true,
      react: { and: ['search', ...(f.react || [])] },
      aggregations: f.type === 'range' ? ['min', 'max'] : undefined,
    })),
    {
      id: 'authors',
      type: 'term',
      dataField: ['author.keyword'],
      defaultQuery: {
        aggs: {
          authors: {
            terms: {
              field: 'authorId',
              size: 15
            },
            aggs: {
              objects: {
                top_hits: {
                  _source: {
                    includes: ['authorId', 'author']
                  },
                  size: 1
                }
              }
            }
          }
        }
      },
      aggregationSize: 15,
      execute: true,
      react: { and: ['search'] }
    }
  ] : []

  if (sellerId) {
    filterValueQueries.push({
      id: 'salesPriceMin',
      type: 'search',
      dataField: ['_score'],
      execute: true,
      react: { and: ['search'] },
      highlight: false,
      size: 1,
      from: 0,
      defaultQuery: { sort: sortQueries.salePrice(sellerId) }
    })
    filterValueQueries.push({
      id: 'salesPriceMax',
      type: 'search',
      dataField: ['_score'],
      execute: true,
      react: { and: ['search'] },
      highlight: false,
      size: 1,
      from: 0,
      defaultQuery: { sort: sortQueries.salePriceDec(sellerId) }
    })
  }

  const filterQueries = []

  const addRelationToQueries = relation => {
    booksQuery.react.and.push(relation)
    filterValueQueries.forEach(q => {
      q.react.and.push(relation)
    })
  }

  if (author) {
    const items = Array.isArray(author) ? author : [author]
    filterQueries.push({
      id: 'authorQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: {
              bool: {
                should: items.map(i => ({ term: { authorId: i } })),
                minimum_should_match: 1,
              }
            }
          },
        }
      }
    })
    addRelationToQueries('authorQuery')
  }

  const available = ['price', 'priceDec'].includes(sortType) ?
    !availableNew && !availableUsed : false

  if (available || availableNew || availableUsed) {
    const should = []
    if (availableNew || available) {
      should.push({
        term: {
          availableNew: true,
        },
      })
    }
    if (availableUsed || available) {
      should.push({
        range: {
          availableUsed: {
            gt: 0,
          },
        },
      })
    }

    filterQueries.push({
      id: 'availabilityQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: [{
              bool: {
                should,
                minimum_should_match: 1,
              },
            }]
          },
        }
      }
    })
    addRelationToQueries('availabilityQuery')
  }

  if (formats) {
    const items = Array.isArray(formats) ? formats : [formats]

    filterQueries.push({
      id: 'formatQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: {
              bool: {
                should: items.map(i => ({ term: { format: i } })),
                minimum_should_match: 1,
              }
            }
          }
        }
      }
    })
    addRelationToQueries('formatQuery')
  }

  if (yearMax || yearMin) {
    filterQueries.push({
      id: 'yearQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: {
              range: {
                year: {
                  gte: yearMin,
                  lte: yearMax,
                }
              }
            }
          }
        }
      }
    })
    addRelationToQueries('yearQuery')
  }

  if (priceMax || priceMin) {
    const should = []

    if (availableUsed && !availableNew) {
      should.push({
        range: {
          priceUsedFrom: {
            gte: parseFloat(priceMin),
            lte: parseFloat(priceMax),
          },
        },
      })
    } else if (availableNew && !availableUsed) {
      should.push({
        range: {
          priceNewFrom: {
            gte: parseFloat(priceMin),
            lte: parseFloat(priceMax),
          },
        },
      })
    } else {
      should.push({
        range: {
          priceUsedFrom: {
            gte: parseFloat(priceMin),
            lte: parseFloat(priceMax),
          },
        },
      })
      should.push({
        range: {
          priceNewFrom: {
            gte: parseFloat(priceMin),
            lte: parseFloat(priceMax),
          },
        },
      })
    }

    filterQueries.push({
      id: 'priceQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: { query: { bool: { must: { bool: { should } } } } }
    })
    addRelationToQueries('priceQuery')
    addRelationToQueries('availableOnly')
  }

  if (ratingMax || ratingMin) {
    filterQueries.push({
      id: 'ratingQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: {
              range: {
                avgRating: {
                  gte: parseFloat(ratingMin),
                  lte: parseFloat(ratingMax),
                }
              }
            }
          }
        }
      }
    })
    addRelationToQueries('ratingQuery')
  }

  if (genres) {
    const items = Array.isArray(genres) ? genres : [genres]

    filterQueries.push({
      id: 'genreQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: {
              bool: {
                should: items.map(i => ({ term: { genresSearch: i } })),
                minimum_should_match: 1,
              }
            }
          }
        }
      }
    })
    addRelationToQueries('genreQuery')
  }

  if (languages) {
    const items = Array.isArray(languages) ? languages : [languages]
    const exists = !items.find(i => i === 'nl')

    filterQueries.push({
      id: 'languageQuery',
      type: 'search',
      dataField: ['_'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must_not: !exists ? [{ exists: { field: 'languages' } }] : undefined,
            must: exists ? {
              bool: {
                should: items
                  .filter(i => i !== 'nl')
                  .map(i => ({ term: { languages: i } })),
                minimum_should_match: 1,
              }
            } : undefined
          }
        }
      }
    })
    addRelationToQueries('languageQuery')
  }

  if (sellerId) {
    addRelationToQueries('sales')
  }

  return [
    booksQuery,
    searchQuery,
    ...availableOnlyQueries,
    ...filterValueQueries,
    ...filterQueries,
  ]
}

const getAuthorForBookQuery = (bookQuery, authorQuery) => {
  return [
    ...bookQuery.map(i => ({ ...i, execute: false })),
    {
      id: 'author_query',
      type: 'search',
      execute: false,
      dataField: [''],
      customQuery: {
        query: {
          bool: {
            should: [
              {
                multi_match: {
                  query: authorQuery,
                  type: 'phrase_prefix',
                  fields: ['author'],
                  tie_breaker: 0,
                  max_expansions: 1000,
                  boost: 20
                }
              },
              {
                multi_match: {
                  query: authorQuery,
                  type: 'best_fields',
                  fields: ['author'],
                  fuzziness: 'AUTO',
                  tie_breaker: 1,
                  minimum_should_match: '30%',
                  boost: 10
                }
              }
            ]
          }
        }
      }
    },
    {
      id: 'author',
      type: 'term',
      dataField: ['author.keyword'],
      defaultQuery: {
        aggs: {
          authors: {
            terms: {
              field: 'authorId'
            },
            aggs: {
              objects: {
                top_hits: {
                  _source: {
                    includes: ['authorId', 'author']
                  },
                  size: 1
                }
              }
            }
          }
        }
      },
      aggregationSize: 10,
      execute: true,
      react: { and: [...bookQuery.find(q => q.id === 'books').react.and, 'author_query'] }
    }
  ]
}

const getSalesQuery = (bookQuery, { sellerId, salePriceMin, salePriceMax, saleStatus }) => {
  const must = [{ term: { 'sales.sellerId': sellerId } }]
  if (salePriceMin || salePriceMax) {
    must.push({
      range: {
        'sales.price': {
          gte: parseFloat(salePriceMin),
          lte: parseFloat(salePriceMax),
        },
      }
    })
  }
  if (saleStatus) {
    must.push({ term: { 'sales.status': saleStatus } })
  }

  return [
    ...bookQuery,
    {
      id: 'sales',
      type: 'search',
      dataField: ['book.sales.sellerId.keyword'],
      execute: false,
      customQuery: {
        query: {
          bool: {
            must: [{
              nested: {
                path: 'sales',
                query: {
                  bool: {
                    must
                  }
                }
              }
            }]
          }
        }
      }
    }
  ]
}

const getBooksQuery = ({
  search = '',
  page = 0,
  perPage = 10,
  ids = [],
  isbns = [],
  authorIds = [],
}) => {
  if (isbns.length) {
    return [{
      id: 'books',
      type: 'search',
      defaultQuery: {
        min_score: 0,
        query: {
          bool: {
            must: [{
              bool: {
                should: isbns.map(fullIsbn => ({ term: { fullIsbn } })),
                minimum_should_match: 1,
              },
            }]
          },
        },
        size: perPage,
        from: page * perPage,
      },
      dataField: ['id'],
      highlight: false,
      sort: [
        { avgRating: { order: 'desc', unmapped_type: 'float' } },
        { votes: { order: 'desc', unmapped_type: 'integer' } },
        { year: { order: 'desc', unmapped_type: 'integer' } },
      ]
    }]
  }

  if (ids.length) {
    return [{
      id: 'books',
      type: 'search',
      defaultQuery: {
        min_score: 0,
        query: {
          bool: {
            must: [{
              bool: {
                should: ids.map(id => ({ term: { id } })),
                minimum_should_match: 1,
              },
            }]
          },
        },
        size: perPage,
        from: page * perPage,
      },
      dataField: ['id'],
      highlight: false,
      sort: [
        { avgRating: { order: 'desc', unmapped_type: 'float' } },
        { votes: { order: 'desc', unmapped_type: 'integer' } },
        { year: { order: 'desc', unmapped_type: 'integer' } },
      ]
    }]
  }

  if (authorIds.length) {
    return [{
      id: 'books',
      type: 'search',
      defaultQuery: {
        min_score: 0,
        query: {
          bool: {
            must: [{
              bool: {
                should: authorIds.map(authorId => ({ term: { authorId } })),
                minimum_should_match: 1,
              },
            }]
          },
        },
        size: perPage,
        from: page * perPage,
      },
      dataField: ['id'],
      highlight: false,
      sort: [
        { avgRating: { order: 'desc', unmapped_type: 'float' } },
        { votes: { order: 'desc', unmapped_type: 'integer' } },
        { year: { order: 'desc', unmapped_type: 'integer' } },
      ]
    }]
  }

  return getBooksSearchQuery({ search, page, perPage, useFilters: false })
}

const getGenresQuery = ({
  search = '',
  page = 0,
  perPage = 10,
  ids = []
}) => {
  if (ids.length) {
    return [{
      defaultQuery: {
        query: {
          bool: {
            must: [{
              bool: {
                should: ids.map(id => ({ term: { id } })),
                minimum_should_match: 1
              }
            }]
          }
        }
      },
      dataField: ['id'],
      id: 'genres',
      size: perPage,
      from: page * perPage,
    }]
  }

  if (!search.length) {
    return [{
      defaultQuery: {
        query: { match_all: {} }
      },
      dataField: ['id'],
      id: 'genres',
      size: perPage,
      from: page * perPage,
    }]
  }

  return [{
    defaultQuery: {
      query: {
        bool: {
          must: [
            {
              multi_match: {
                query: search.trim(),
                type: 'phrase_prefix',
                fields: ['no^2', 'sv^2', 'en'],
                minimum_should_match: '30%',
              },
            },
          ],
        }
      }
    },
    dataField: ['id'],
    id: 'genres',
    size: perPage,
    from: page * perPage,
  }]
}

const getAuthorsQuery = ({ search = '', page = 0, perPage = 10, exact = false, validOnly = false }) => {
  const query = [{
    defaultQuery: {
      query: {
        bool: {
          must: [
            {
              multi_match: {
                query: search.trim(),
                type: 'phrase_prefix',
                fields: ['name^2', 'firstName', 'lastName'],
                minimum_should_match: exact ? '100%' : '40%',
              },
            },
          ],
        },
      }
    },
    dataField: ['id'],
    id: 'authors',
    size: perPage,
    from: page * perPage,
  }]
  
  if (validOnly) {
    query[0].defaultQuery.query.bool.must.push({ term: { isValid: true } })
  }
  
  return query
}

const getSuggestionsQuery = ({ search = '', page = 0, perPage = 10 }) => {
  return [{
    defaultQuery: {
      query: {
        match: { suggest: search.trim() }
      },
      suggest: {
        gotsuggest: {
          prefix: search.trim(),
          completion: {
            field: 'suggest',
            fuzzy: false,
          },
        },
      },
    },
    dataField: ['id'],
    id: 'suggestions',
    size: perPage,
    from: page * perPage,
  }]
}

const getUsersQuery = ({ search = '', page = 0, perPage = 10 }) => {
  return [{
    defaultQuery: { query: {
        bool: {
          must: {
            match: {
              name: {
                query: search.trim(),
                fuzziness: 5,
                prefix_length: 2,
                max_expansions: 500,
              },
            },
          },
          should: [{ exists: { field: 'image' } }],
        },
      } },
    dataField: ['id'],
    id: 'users',
    size: Math.min(perPage, 100),
    from: page * perPage,
  }]
}

const getConsolidatedBookQuery = ({ bookId, consolidatedBookId }) => {
  const must = []

  if (bookId) {
    must.push({
      term: {
        books: {
          value: bookId,
        },
      },
    })
  }

  if (consolidatedBookId) {
    must.push({
      term: {
        id: {
          value: consolidatedBookId,
        },
      },
    })
  }

  return [{
    defaultQuery: {
      query: {
        bool: { must },
      }
    },
    dataField: ['id'],
    id: 'consolidatedBook',
    size: 1,
  }]
}

const getBookQuery = ({ id }) => {
  return [{
    defaultQuery: { query: { match: { id } } },
    dataField: ['id'],
    id: 'book',
  }]
}

const getUserQuery = ({ id }) => {
  return [{
    defaultQuery: { query: { match: { id } } },
    dataField: ['id'],
    id: 'user',
  }]
}

module.exports = {
  getBooksSearchQuery,
  getAuthorForBookQuery,
  getSalesQuery,
  getBooksQuery,
  getGenresQuery,
  getAuthorsQuery,
  getSuggestionsQuery,
  getUsersQuery,
  getConsolidatedBookQuery,
  getBookQuery,
  getUserQuery,
}
