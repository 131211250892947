/* eslint-disable no-underscore-dangle */
const getLodash = require('lodash/get')
const sortByLodash = require('lodash/sortBy')
const minByLodash = require('lodash/minBy')
const orderByLodash = require('lodash/orderBy')
const uniqWithLodash = require('lodash/uniqWith')
const isEqualLodash = require('lodash/isEqual')
const uniqByLodash = require('lodash/uniqBy')

const stringSimilarity = require('string-similarity')
const ISBN = require('simple-isbn').isbn
const {
  getBooksQuery,
  getGenresQuery,
  getAuthorsQuery,
  getSuggestionsQuery,
  getUsersQuery,
  getBooksSearchQuery,
  getAuthorForBookQuery,
  getSalesQuery,
  getConsolidatedBookQuery,
  getBookQuery,
  getUserQuery
} = require('./queryManager')
const { reactivesearch } = require('./appbaseSDK')

const combineSearchFilters = (result, { availableNew, availableUsed, search }) => {
  const languagesKeys = getLodash(result, 'languages.aggregations["languages"].buckets') || []
  const languages = languagesKeys.filter((i) => !!i.doc_count).map((i) => i.key)

  const formatKeys = getLodash(result, 'format.aggregations["format"].buckets') || []
  const formats = formatKeys.filter((i) => !!i.doc_count).map((i) => i.key)

  const availability = []

  const availableUsedKeys = getLodash(result, 'availableUsed.aggregations["availableUsed"].buckets') || []
  if (availableUsedKeys.find((i) => !!i.key && !!i.doc_count)) {
    availability.push('availableUsed')
  }

  const availableNewKeys = getLodash(result, 'availableNew.aggregations["availableNew"].buckets') || []
  if (availableNewKeys.find((i) => !!i.key && !!i.doc_count)) {
    availability.push('availableNew')
  }

  const genresKeys = getLodash(result, 'genres.aggregations["genres"].buckets') || []
  const genres = genresKeys.filter((i) => !!i.doc_count).map((i) => i.key)

  const authorsKeys = getLodash(result, `authors.aggregations.authors.buckets`) || []
  const authors = authorsKeys.filter((i) => !!i.doc_count).map((i) => getLodash(i, 'objects.hits.hits[0]._source'))

  let authorObjects = []
  if (authors.length && search) {
    const authorsWithRating = stringSimilarity.findBestMatch(
      search,
      authors.map((i) => i.author)
    ).ratings
    const sortedAuthors = sortByLodash(authorsWithRating, 'rating').reverse()

    authorObjects = sortedAuthors.map((i) => authors.find((a) => a.author === i.target))
  }

  let min = 0
  let max = 0

  // eslint-disable-next-line no-bitwise
  if (!(availableNew ^ availableUsed)) {
    const minNew = getLodash(result, 'priceNewFrom.aggregations.min.value') || 0
    const minUsed = getLodash(result, 'priceUsedFrom.aggregations.min.value') || 0

    if (minNew && minUsed) {
      min = Math.min(minNew, minUsed)
    } else if (minNew) {
      min = minNew
    } else if (minUsed) {
      min = minUsed
    }

    const maxNew = getLodash(result, 'priceNewFrom.aggregations.max.value') || 0
    const maxUsed = getLodash(result, 'priceUsedFrom.aggregations.max.value') || 0

    if (maxNew && maxUsed) {
      max = Math.max(maxNew, maxUsed)
    } else if (maxNew) {
      max = maxNew
    } else if (maxUsed) {
      max = maxUsed
    }
  } else if (availableUsed) {
    min = getLodash(result, 'priceUsedFrom.aggregations.min.value') || 0
    max = getLodash(result, 'priceUsedFrom.aggregations.max.value') || 0
  } else if (availableNew) {
    min = getLodash(result, 'priceNewFrom.aggregations.min.value') || 0
    max = getLodash(result, 'priceNewFrom.aggregations.max.value') || 0
  }

  const maxYearReceived = getLodash(result, 'year.aggregations.max.value') || 0
  const maxYear = maxYearReceived.toString() === '9999' ? new Date().getFullYear() : maxYearReceived

  const minYearReceived = getLodash(result, 'year.aggregations.min.value') || 1900
  const minYear = minYearReceived.toString() === '9999' ? 1900 : minYearReceived

  const minSalePrice = getLodash(result, 'salesPriceMin.hits.hits[0].sort[0]') || 0
  const maxSalePrice = getLodash(result, 'salesPriceMax.hits.hits[0].sort[0]') || 0

  return {
    languages,
    year: {
      min: minYear,
      max: maxYear
    },
    formats,
    availability,
    price: { min, max },
    salePrice: { min: minSalePrice, max: maxSalePrice },
    rating: {
      min: getLodash(result, 'avgRating.aggregations.min.value') || 0,
      max: getLodash(result, 'avgRating.aggregations.max.value') || 0
    },
    genres,
    authors: authorObjects
  }
}

module.exports = class ElasticSearchAPI {
  constructor(indexSuffix = 'no') {
    this.indexSuffix = indexSuffix
  }

  setIndexSuffix(indexSuffix) {
    this.indexSuffix = indexSuffix
  }

  getIndexName(name) {
    if (name === 'book') {
      return `${name}_index_${this.indexSuffix.toLowerCase()}`
    }
    return `${name}_search_${this.indexSuffix.toLowerCase()}`
  }

  static getPagination(query, total) {
    return {
      current_page: query.page,
      per_page: query.perPage,
      total_count: total,
      total_pages: Math.ceil(total / query.perPage)
    }
  }

  async processBooks({ search, books }) {
    const consolidatedBooks = books.map((book) => book.consolidatedBookId)
    const similarBooksIds = books.reduce((similarBooks, b) => similarBooks.concat(b.similarBooks), [])
    const mainIsbn = ISBN.isValidIsbn(search) ? search : null

    const similarBooksResult =
      similarBooksIds.length &&
      (await this.getBooks({
        perPage: similarBooksIds.length,
        ids: similarBooksIds.map((smi) => smi.id)
      }))
    const similarBooks = similarBooksResult && similarBooksResult.data.array

    const mainBooks = []

    // Find books which need to add versions
    // eslint-disable-next-line no-restricted-syntax
    for (const consolidatedBookId of consolidatedBooks) {
      const booksWithDescriptionAndImage = similarBooks.filter(
        (b) => (b.smallImage || b.mediumImage || b.image) && b.description
      )

      const booksToFilter = booksWithDescriptionAndImage.length ? booksWithDescriptionAndImage : similarBooks
      const varBooks = booksToFilter.filter((b) => b.consolidatedBookId === consolidatedBookId)
      const cheapestPriceUsedAmount = minByLodash(varBooks, 'priceUsedFrom')
      const cheapestPriceNewFrom = minByLodash(varBooks, 'priceNewFrom')
      const allBooks = similarBooks.filter((b) => b.consolidatedBookId === consolidatedBookId)
      const mainByIsbn = mainIsbn && mainIsbn !== '' && allBooks.find((b) => b.fullIsbn === mainIsbn)
      if (mainByIsbn) {
        mainBooks.push(mainByIsbn)
      } else if (!cheapestPriceUsedAmount && !cheapestPriceNewFrom) {
        mainBooks.push(books.find((b) => b.consolidatedBookId === consolidatedBookId))
      } else if (!cheapestPriceUsedAmount && cheapestPriceNewFrom) {
        mainBooks.push(cheapestPriceNewFrom)
      } else if (!cheapestPriceNewFrom && cheapestPriceUsedAmount) {
        mainBooks.push(cheapestPriceUsedAmount)
      } else if (cheapestPriceUsedAmount.priceUsedFrom < cheapestPriceNewFrom.priceNewFrom) {
        mainBooks.push(cheapestPriceUsedAmount)
      } else {
        mainBooks.push(cheapestPriceNewFrom)
      }
    }

    const similarBooksWithoutMainList =
      similarBooks && similarBooks.length
        ? mainBooks.reduce((result, book) => {
            const similarBooksWithoutMain = similarBooks.filter(
              (sm) => sm.consolidatedBookId === book.consolidatedBookId && sm.id !== book.id
            )
            return result.concat(similarBooksWithoutMain)
          }, [])
        : []

    const availableSimilarBooks = orderByLodash(
      similarBooksWithoutMainList.filter((book) => book.availableNew || book.availableUsed),
      (item) => item.year,
      ['desc']
    )
    const unavailableSimilarBooks = orderByLodash(
      similarBooksWithoutMainList.filter((book) => !availableSimilarBooks.includes(book)),
      (item) => item.year,
      ['desc']
    )
    const bookVersionList = availableSimilarBooks.concat(unavailableSimilarBooks)

    return mainBooks
      .filter((book) => !bookVersionList.find((bvl) => bvl.id === book.id))
      .map((book) => {
        const bookVersions = bookVersionList.filter((sm) => sm.consolidatedBookId === book.consolidatedBookId)

        if (bookVersions.length) {
          const versions = [...bookVersions, { ...book }]

          const versionsSortedByYear = sortByLodash(versions, 'year').reverse()

          const versionsSortedByYearAndAvailability = sortByLodash(
            versionsSortedByYear,
            (b) => !(b.availableNew || b.availableUsed)
          )

          return {
            ...book,
            versions: versionsSortedByYearAndAvailability
          }
        }

        return book
      })
  }

  async quickSearch(search) {
    try {
      if (!search || search.length < 4) {
        return {
          code: 0,
          data: {
            suggestions: [],
            books: {
              array: [],
              count: 0
            },
            genres: [],
            author: []
          }
        }
      }

      const booksQuery = getBooksQuery({ search })
      const genresQuery = getGenresQuery({ search })
      const authorsQuery = getAuthorsQuery({ search })
      const suggestionsQuery = getSuggestionsQuery({ search })

      const [booksResult, genresResult, authorsResult, suggestionsResult] = await Promise.all([
        reactivesearch(this.getIndexName('book'), booksQuery),
        reactivesearch(this.getIndexName('genre'), genresQuery),
        reactivesearch(this.getIndexName('author'), authorsQuery),
        reactivesearch(this.getIndexName('suggestion'), suggestionsQuery)
      ])

      const genres = (getLodash(genresResult, 'genres.hits.hits') || []).map((genre) => genre._source)
      const suggestions = uniqWithLodash(
        (getLodash(suggestionsResult, 'suggestions.suggest.gotsuggest[0].options') || []).map(
          (suggestion) => suggestion._source.title
        ),
        isEqualLodash
      )
      const authors = (getLodash(authorsResult, 'authors.hits.hits') || []).map((author) => author._source)

      const count = getLodash(booksResult, 'books.hits.total.value') || 0

      const array = await this.processBooks({
        books: (getLodash(booksResult, 'books.hits.hits') || []).map((book) => book._source),
        search
      })

      return {
        code: 0,
        data: {
          genres,
          suggestions,
          authors,
          books: {
            array: array.slice(0, 3),
            count
          }
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async searchBooks(input = {}, useConsolidatedLogic = true) {
    try {
      const query = {
        perPage: 20,
        page: 0,
        ...input
      }

      const appbaseQuery = getBooksSearchQuery(query)

      const result = await reactivesearch(this.getIndexName('book'), appbaseQuery)

      const filters = combineSearchFilters(result, query)

      const count = getLodash(result, 'books.hits.total.value') || 0
      const books = (getLodash(result, 'books.hits.hits') || []).map((book) => book._source)

      const array = await (useConsolidatedLogic
        ? this.processBooks({
            books,
            search: query.search
          })
        : books)

      return {
        code: 0,
        data: {
          filters,
          array,
          pagination: ElasticSearchAPI.getPagination(query, count)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getGenres(input) {
    try {
      const query = {
        perPage: 20,
        page: 0,
        ...input
      }

      const appbaseQuery = getGenresQuery(query)

      const result = await reactivesearch(this.getIndexName('genre'), appbaseQuery)

      // eslint-disable-next-line no-underscore-dangle
      const genres = (getLodash(result, 'genres.hits.hits') || []).map((genre) => genre._source)
      const total = getLodash(result, 'genres.hits.total.value') || 0

      return {
        code: 0,
        data: {
          array: genres,
          pagination: ElasticSearchAPI.getPagination(query, total)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getUsers(input) {
    try {
      const query = {
        perPage: 20,
        page: 0,
        ...input
      }

      const appbaseQuery = getUsersQuery(query)

      const result = await reactivesearch(this.getIndexName('user'), appbaseQuery)

      // eslint-disable-next-line no-underscore-dangle
      const users = (getLodash(result, 'users.hits.hits') || []).map((user) => user._source)
      const total = getLodash(result, 'users.hits.total.value') || 0

      return {
        code: 0,
        data: {
          array: users,
          pagination: ElasticSearchAPI.getPagination(query, total)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getBooks(input) {
    try {
      const query = {
        perPage: 20,
        page: 0,
        ...input
      }

      const appbaseQuery = getBooksQuery(query)

      const result = await reactivesearch(this.getIndexName('book'), appbaseQuery)

      // eslint-disable-next-line no-underscore-dangle
      const books = (getLodash(result, 'books.hits.hits') || []).map((book) => book._source)
      const total = getLodash(result, 'books.hits.total.value') || 0

      return {
        code: 0,
        data: {
          array: books,
          pagination: ElasticSearchAPI.getPagination(query, total)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getAuthors(input) {
    try {
      const query = {
        perPage: 20,
        page: 0,
        ...input
      }

      const appbaseQuery = getAuthorsQuery(query)

      const result = await reactivesearch(this.getIndexName('author'), appbaseQuery)

      // eslint-disable-next-line no-underscore-dangle
      const authors = (getLodash(result, 'authors.hits.hits') || []).map((author) => author._source)
      const total = getLodash(result, 'authors.hits.total.value') || 0

      return {
        code: 0,
        data: {
          array: authors,
          pagination: ElasticSearchAPI.getPagination(query, total)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getAuthorsForBookSearch(authorQuery, bookQuery) {
    try {
      const bookQueryRequest = getBooksSearchQuery({ ...bookQuery, useFilters: false })
      const appbaseQuery = getAuthorForBookQuery(bookQueryRequest, authorQuery)

      const result = await reactivesearch(this.getIndexName('book'), appbaseQuery)

      const keys = getLodash(result, `author.aggregations.authors.buckets`) || []
      const total = getLodash(result, 'author.hits.total.value') || 0
      const authors = keys.map((i) => getLodash(i, 'objects.hits.hits[0]._source')).filter((i) => !!i)

      const authorsWithRating = stringSimilarity.findBestMatch(
        authorQuery,
        authors.map((i) => i.author)
      ).ratings
      const sortedAuthors = sortByLodash(authorsWithRating, 'rating').reverse()

      const authorObjects = sortedAuthors.map((i) => authors.find((a) => a.author === i.target))

      return {
        code: 0,
        data: {
          array: uniqByLodash(authorObjects, 'authorId'),
          pagination: ElasticSearchAPI.getPagination({ page: 0, perPage: 10 }, total)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getSales(input, bookQuery) {
    try {
      const query = {
        perPage: 20,
        page: 0,
        ...input
      }

      const bookQueryRequest = getBooksSearchQuery({
        ...bookQuery,
        sellerId: query.sellerId,
        page: query.page,
        perPage: query.perPage
      })
      const appbaseQuery = getSalesQuery(bookQueryRequest, query)

      const result = await reactivesearch(this.getIndexName('book'), appbaseQuery)

      // eslint-disable-next-line no-underscore-dangle
      const books = (getLodash(result, 'books.hits.hits') || []).map((book) => book._source)
      const total = getLodash(result, 'books.hits.total.value') || 0

      const filters = combineSearchFilters(result, bookQuery)

      const sales = books.map((b) => ({
        ...minByLodash(
          b.sales.filter((s) => s.sellerId === query.sellerId),
          'popularity'
        ),
        book: { ...b, currentSellerSales: b.sales.filter((s) => s.sellerId === query.sellerId) }
      }))

      return {
        code: 0,
        data: {
          array: sales,
          filters,
          pagination: ElasticSearchAPI.getPagination(query, total)
        }
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getConsolidatedBook({ bookId, consolidatedBookId }) {
    try {
      const appbaseQuery = getConsolidatedBookQuery({ bookId, consolidatedBookId })

      const result = await reactivesearch(this.getIndexName('consolidated_book'), appbaseQuery)

      return {
        code: 0,
        data: getLodash(result, 'consolidatedBook.hits.hits[0]._source')
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getBookById(id) {
    try {
      const appbaseQuery = getBookQuery({ id })
      const result = await reactivesearch(this.getIndexName('book'), appbaseQuery)

      return {
        code: 0,
        data: getLodash(result, 'book.hits.hits[0]._source')
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }

  async getUserById(id) {
    try {
      const appbaseQuery = getUserQuery({ id })
      const result = await reactivesearch(this.getIndexName('user'), appbaseQuery)

      return {
        code: 0,
        data: getLodash(result, 'user.hits.hits[0]._source')
      }
    } catch (error) {
      return {
        code: 1,
        error
      }
    }
  }
}
